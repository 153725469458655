body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1e2134;
  background: #fbfcfe;
}

body * {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body a {
  all: unset;
  color: inherit;
  text-decoration: none;
}

body a:hover {
  color: inherit;
}

* {
  box-sizing: border-box;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("../public/fonts/inter-v3-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */
      url("../public/fonts/inter-v3-latin-regular.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url("../public/fonts/inter-v3-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../public/fonts/inter-v3-latin-500.woff")
      format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("../public/fonts/inter-v3-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../public/fonts/inter-v3-latin-600.woff")
      format("woff"),
    /* Modern Browsers */ url("../public/fonts/inter-v3-latin-600.ttf")
      format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: url("../public/fonts/inter-v3-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../public/fonts/inter-v3-latin-700.woff")
      format("woff");
}

@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  src: url("../public/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: bold;
  src: url("../public/fonts/Montserrat-Bold.ttf") format("truetype");
}

@media only screen and (max-width: 400px) {
  ul.mde-header-group {
    max-width: 280px;
  }
}
